import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {createPaymentSession} from '../../services/CheckoutService'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

const style = (theme) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  [theme.breakpoints.down('md')]: {
    width: 300
  },
});

const EmailModal = (props) => {
  const [email, setEmail] = useState();

  const handleCheckout = async () => {

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


    const stripe = await stripePromise;
    createPaymentSession(props.cart, email, props.totalPrice).then((session) => {
      stripe.redirectToCheckout({ sessionId: session.id })
    })
  }

  const handleClose = () => {
    props.setOpen(false);
    setEmail();
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please enter your email to checkout
          </Typography>
          <TextField id="outlined-basic" label="Email" variant="outlined"
            onChange={event => setEmail(event.target.value)} sx={{ m: 2, width: '80%' }} />
          <Button variant='contained' size="large" onClick={handleCheckout}
            sx={{
              ml: 2, mr: 2, mt: 0.5,
              width: '80%',
              color: 'white', mb: 2, backgroundColor: '#000000',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
            }}>Continue</Button>
        </Box>
      </Modal>
    </div>
  )
}

export default EmailModal;