import React, { useState, useEffect } from 'react'
import { getMostLikedProducts } from '../services/ProductService'
import { convertPrice } from '../utilities/ConvertPrice'
import { Link } from 'react-router-dom';
import PathImages from '../assets/images/PathImages';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const MostLikedProduct = (props) => {
    const [likedProducts, setLikedProducts] = useState();

    useEffect(() => {
        window.scrollTo(0, 0)
        getMostLikedProducts({ category: props.category, productId: props.productId })
            .then(p => setLikedProducts(p))
    }, [props]);

    const productCard = (product) => {
        var paths = (function () {
            let images = []
            PathImages.map((element) => {
                if (element["productId"] === product.id) {
                    images.push(element.path)
                }
            });
            return images;
        })(); 

        return (
            <Card sx={{ p: 0.5, m: 1 }}>
                <Link
                    to={`/${product.id}`}
                >
                    <CardMedia
                        sx={{ height: 260 , width: "100%" }}
                        image={paths[0]}
                        title='green iguana'
                    />
                </Link>
                <CardContent sx={{ p: 0.5, pl: 1 }}>
                    <Typography variant='p' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                        {product.name}
                    </Typography>
                </CardContent>
                <CardContent sx={{ p: 0.5, pl: 1 }}>
                    <Typography variant='p' color='text.secondary'>
                        $ {convertPrice(product, props.currency)}
                    </Typography>
                </CardContent>
            </Card>
        )
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} >
                    {likedProducts !== undefined && likedProducts.map((product, index) => (
                        <Grid item {...{ xs: 12, sm: 6, md: 4 }} key={index} justifyContent="center">
                            {productCard(product)}
                        </Grid>
                    ))
                    }
                </Grid>
            </Box>
        </>
    )
}

export default MostLikedProduct;