import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const Return = () => {
    return(
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ m: 2 }}>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/"
                >
                    Home
                </Link>
                <Typography color="text.primary">Returns & Exchange</Typography>
            </Breadcrumbs>
            <Card sx={{ p: 1, mt: 3, mb:2 }}>
                <CardContent sx={{ p: 0.5, pl: 1 }}>
                    <Typography variant='h6' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                        RETURNS & EXCHANGES
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        We have a 14-day return policy, which means you have 14
                        days after receiving your item to request a return.
                        To be eligible for a return, your item must be in the same
                        condition that you received it, unworn or unused, with
                        tags, and in its original packaging. You’ll also need the
                        receipt or proof of purchase.
                        To start a return, you can contact us
                        at sales@blaxmit.com. If your return is accepted, we’ll
                        send you a return shipping label, as well as instructions on
                        how and where to send your package. Items sent back to
                        us without first requesting a return will not be accepted.
                        You can always contact us for any return question
                        at sales@blaxmit.com.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Damages & Issues
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        Please inspect your order upon reception and contact us
                        immediately if the item is defective, damaged or if you
                        receive the wrong item, so that we can evaluate the issue
                        and make it right.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Exceptions / non-returnable items
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        Certain types of items cannot be returned, like perishable
                        goods (such as food, flowers, or plants), custom products
                        (such as special orders or personalized items), and
                        personal care goods (such as beauty products). We also
                        do not accept returns for hazardous materials, flammable
                        liquids, or gases. Please get in touch if you have questions
                        or concerns about your specific item.
                        Unfortunately, we cannot accept returns on sale items or
                        gift cards.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Exchanges
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        The fastest way to ensure you get what you want is to
                        return the item you have, and once the return is accepted,
                        make a separate purchase for the new item.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Refunds
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        We will notify you once we’ve received and inspected your
                        return, and let you know if the refund was approved or not.
                        If approved, you’ll be automatically refunded on your
                        original payment method. Please remember it can take
                        some time for your bank or credit card company to
                        process and post the refund too.
                    </Typography>
                </CardContent>
            </Card>
        </>
    )

}

export default Return;