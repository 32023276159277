import React, { useState, useEffect, createRef } from "react"
import { useParams } from 'react-router-dom';
import { getProductById } from '../services/ProductService'
import { convertPrice, convertPriceId, currencySymbol } from '../utilities/ConvertPrice'
import Reviews from './Reviews'
import PathImages from '../assets/images/PathImages'
import ProductImageList from "./ProductImageList";
import MostLikedProduct from "./MostLikedProducts";
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import { styled } from '@mui/material/styles';

const Product = (props) => {
    const [product, setProduct] = useState();
    const [open, setOpen] = useState(false);
    //snackbar message
    const [message, setMessage] = useState();
    //snackbar severity
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false)
    const { id } = useParams();
    const rootElement = document.getElementById("reviews");
    const ref = createRef(rootElement);
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#000000',
        },
        '& .MuiRating-iconHover': {
            color: '#000000',
        },
    });


    var paths = (function () {
        let images = []
        PathImages.map((element) => {
            if (element["productId"] === id) {
                images.push(element.path)
            }
        });
        return images;
    })();

    useEffect(() => {
        if (id !== undefined)
            getProductById(id).then(p => setProduct(p))
    }, [id])

    const handleClose = () => {
        setOpen(false);
    };

    const addToCart = () => {
        let cartArr = JSON.parse(localStorage.getItem('carts')) || [];
        let index = cartArr.findIndex(e => e.productId === id);

        if (cartArr.length !== 0 && props.currency !== cartArr[0].currency) {
            setOpen(true)
            setMessage(`Currency of your shopping cart is ${cartArr[0].currency}. Please change the current currency to ${cartArr[0].currency}.`)
            setError(true)
            return;
        }

        if (cartArr.length === 0) {
            var cartObj = {
                productId: id,
                name: product.name,
                quantity: 1,
                priceId: convertPriceId(product, props.currency),
                price: convertPrice(product, props.currency),
                inventory: product.inventory,
                cartItemPrice: convertPrice(product, props.currency) * 1,
                currency: props.currency
            }
            cartArr.push(cartObj)
        } else if (index < 0) {
            var cartObj = {
                productId: id,
                name: product.name,
                quantity: 1,
                priceId: convertPriceId(product, props.currency),
                price: convertPrice(product, props.currency),
                inventory: product.inventory,
                cartItemPrice: convertPrice(product, props.currency) * 1,
                currency: props.currency
            }
            cartArr.push(cartObj)
        }
        else {
            cartArr[index].quantity++
            cartArr[index].cartItemPrice = cartArr[index].price * cartArr[index].quantity
        }

        localStorage.setItem('carts', JSON.stringify(cartArr));
        props.setCart(cartArr)
        const total = cartArr.reduce((a, v) => a = a + v.cartItemPrice, 0).toFixed(2)
        props.setTotalPrice(Number(total))
        setOpen(true)
        setMessage(`${props.product.name} added to the bag`)
        setSuccess(true)
    }

    const referToReviews = () => {
        ref.current.scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <>
            {product !== undefined &&
                <Breadcrumbs aria-label="breadcrumb" sx={{ m: 2 }}>
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/"
                    >
                        All Products
                    </Link>
                    <Typography sx={{ textTransform: 'capitalize' }} color="text.primary">{product.category}</Typography>
                    <Typography sx={{ textTransform: 'capitalize' }} color="text.primary" >{product.name}</Typography>
                </Breadcrumbs>
            }
            <Grid container spacing={2}>
                <Grid item {...{ xs: 12, sm: 7, md: 7 }}>
                    <ProductImageList paths={paths} />
                </Grid>
                <Grid item {...{ xs: 12, sm: 5, md: 5 }} >
                    {product !== undefined && <CardContent>
                        <Typography variant="h5" component="div" sx={{ textTransform: 'capitalize' }}>
                            {product.name}
                        </Typography>
                        <Typography  color="text.secondary" gutterBottom>
                            {currencySymbol(props.currency)} {convertPrice(product, props.currency)}
                        </Typography>
                        <StyledRating name="read-only" value={product.rateAverage} readOnly sx={{ mb: 1.5 }} />
                        <Button variant="text" sx={{ mb: 1.5 }} onClick={referToReviews}>Reviews</Button>
                        {product.inventory !== 0 ?
                            <Button variant='contained' size="large" onClick={addToCart}
                                sx={{
                                    width: '100%',
                                    color: 'white', mb: 2, backgroundColor: '#000000',
                                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
                                }}>ADD TO BAG</Button>
                            : <Button variant='contained' size="large" disabled
                                sx={{
                                    width: '100%',
                                    color: 'white', mb: 2, backgroundColor: '#000000',
                                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
                                }}>OUT OF STOCK</Button>}
                        { error && <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                {message}
                            </Alert>
                        </Snackbar>}
                        {success && <Alert oseverity="success" sx={{ width: '100%' }}>
                            {message}
                        </Alert>}
                        <List disablePadding dense
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            aria-label="contacts"
                        >
                            <ListItem disablePadding>
                                <LocalShippingIcon sx={{ paddingRight: '5px' }} />
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }} primary="Free shipping over $100" />
                            </ListItem>
                            <ListItem disablePadding>
                                <PublicIcon sx={{ paddingRight: '5px' }} />
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }} primary="Worldwide shipping" />
                            </ListItem>
                            <ListItem disablePadding>
                                <SyncAltIcon sx={{ paddingRight: '5px' }} />
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }} primary="14 day return" />
                            </ListItem>
                            <ListItem disablePadding>
                                <SecurityIcon sx={{ paddingRight: '5px' }} />
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }} primary="Secure checkout" />
                            </ListItem>
                        </List>
                        <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                        <Typography variant="body2" sx={{ fontSize: 18 }}>
                            Product Description :
                        </Typography>
                        <List disablePadding dense
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            aria-label="contacts"
                        >
                            <ListItem disablePadding>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }} primary="Handmade in Italy" />
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }} primary="Made in Sterling Silver 925" />
                            </ListItem>
                            {product.weight && <ListItem disablePadding>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }}>
                                    Weight : {product.weight}
                                </ListItemText>
                            </ListItem>}
                            {product.length && <ListItem disablePadding>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }}>
                                    Length : {product.length}
                                </ListItemText>
                            </ListItem>}
                            {product.width && <ListItem disablePadding>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary' }}>
                                    Width : {product.width}
                                </ListItemText>
                            </ListItem>}
                            {product.size && <ListItem disablePadding>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', color: 'text.secondary', textTransform: 'capitalize' }}>
                                    Size : {product.size}
                                </ListItemText>
                            </ListItem>}
                        </List>
                        <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                        <Typography color="text.secondary" fontSize={14}>
                            Our jewelry is made in highest quality sterling silver with %92.5 purity.
                            Even the highest quality sterling silver tarnishes when exposed to salty air, chlorine, sulfur, humidity, perspiration, cosmetics, household bleach and other strong chemicals.
                            To prevent tarnish as much as possible, we recommend that you store your fine jewelry in a safe, dry place when engaging in the following activities:
                            Household and outdoor chores
                            Showering, bathing or swimming in pools, hot tubs, hot springs and the ocean
                            Using lotion, hair products, fragrances and other cosmetics
                            Exercising at the gym or during contact sports
                            Preparing meals
                            Polish your sterling silver piece using a simple microfiber cloth or special jewelry cloth (not paper towels or toilet paper).
                        </Typography>
                    </CardContent>}
                </Grid>
            </Grid>
            <Divider m={1} />
            <Typography variant="h5" color="text.primary" m={2}>YOU MIGHT ALSO LIKE</Typography>
            {product !== undefined && <MostLikedProduct category={product.category} productId={product.id} currency={props.currency} />}
            <Reviews  productId={id} ref={ref} />
        </>
    )
}

export default Product;
