import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CurrencyChange = (props) => {
    const handleChange = (event) => {
        props.setCurrency(event.target.value);
    };

    return (
        <>
            <FormControl sx={{
                m: 1, minWidth: 70 }} size="small">
                <InputLabel id="demo-simple-select-autowidth-label" sx={{ '&:focus': { outline: 0 } }}></InputLabel>
                <Select sx={{ fontSize: 12, fontWeight: "bold", backgroundColor: 'white' }}
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={props.currency}
                    label="Currency"
                    onChange={handleChange}
                >
                    <MenuItem sx={{ fontSize: 12 }} value="CAD">CAD</MenuItem>
                    <MenuItem sx={{ fontSize: 12 }} value="USD">USD</MenuItem>
                </Select>
            </FormControl>
        </>
    )

}

export default CurrencyChange