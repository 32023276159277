import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const Shipping = () => {
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ m: 2 }}>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/"
                >
                    Home
                </Link>
                <Typography color="text.primary">Shipping</Typography>
            </Breadcrumbs>
            <Card sx={{ p: 1, mt: 3 , mb:2}}>
                <CardContent sx={{ p: 0.5, pl: 1 }}>
                    <Typography variant='h6' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                        Shipping options at checkout page
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        Standard Shipping within North America with an estimated
                        delivery time of 3-5 business days.

                        Expedited Shipping within North American with an
                        estimated delivery time of 2-3 business days.

                        All Shipments include tracking information directly sent to
                        your email address registered at the time of checkout. We
                        highly recommend downloading the shop app to get real
                        time updates on your shipment.

                        Our shipments include insurance on every shipment we
                        ship for the full purchase price of your order. Rest assured
                        that you will receive your order safe and securely.

                        Please note: Free Shipping is available when you spend a
                        minimum of $100 including expedited shipping to get your
                        order delivered faster.

                        Don't forget, every order includes complimentary gift
                        packaging!

                        Alternatively, the order note located in the cart can be
                        used to include special shipping requests for your order.
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default Shipping
