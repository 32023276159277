import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { convertPrice, convertPriceId, currencySymbol } from '../utilities/ConvertPrice'
import PathImages from '../assets/images/PathImages'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Alert from '@mui/material/Alert';

const ProductCard = (props) => {
    const [open, setOpen] = useState(false);
    //snackbar message
    const [message, setMessage] = useState();
    //snackbar severity
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false)

    var paths = (function () {
        let images = []
        PathImages.map((element) => {
            if (element["productId"] === props.product.id) {
                images.push(element.path)
            }
        });
        return images;
    })();

    const handleClose = () => {
        setOpen(false);
    };

    const addToCart = () => {
        let cartArr = JSON.parse(localStorage.getItem('carts')) || [];
        let index = cartArr.findIndex(e => e.productId === props.product.id);

        if (cartArr.length !== 0 && props.currency !== cartArr[0].currency) {
            setOpen(true)
            setMessage(`Currency of your shopping cart is ${cartArr[0].currency}. Please change the current currency to ${cartArr[0].currency}.`)
            setError(true)
            return;
        }

        if (cartArr.length === 0) {
            let newCartObj = {
                productId: props.product.id,
                name: props.product.name,
                quantity: 1,
                priceId: convertPriceId(props.product, props.currency),
                price: convertPrice(props.product, props.currency),
                inventory: props.product.inventory,
                cartItemPrice: convertPrice(props.product, props.currency) * 1,
                currency: props.currency
            }
            cartArr.push(newCartObj)
        } else if (index < 0) {
            let cartObj = {
                productId: props.product.id,
                name: props.product.name,
                quantity: 1,
                priceId: convertPriceId(props.product, props.currency),
                price: convertPrice(props.product, props.currency),
                inventory: props.product.inventory,
                cartItemPrice: convertPrice(props.product, props.currency) * 1,
                currency: props.currency
            }

            cartArr.push(cartObj)
        }
        else {
            cartArr[index].quantity++
            cartArr[index].cartItemPrice = cartArr[index].price * cartArr[index].quantity
        }

        localStorage.setItem('carts', JSON.stringify(cartArr));
        props.setCart(cartArr)
        const total = cartArr.reduce((a, v) => a = a + v.cartItemPrice, 0).toFixed(2)
        props.setTotalPrice(total)
        setOpen(true)
        setMessage(`${props.product.name} added to the bag`)
        setSuccess(true)
    }

    return (
        <>
            <Card sx={{ p: 0.5, m: 1 }}>
                <Link
                    to={`/${props.product.id}`}
                >
                    {paths && <CardMedia
                        component="img"
                        height="350"
                        image={paths[0]}
                        alt={props.product.name}
                    />}
                </Link>
                <CardContent sx={{ p: 0.5, pl: 1 }}>
                    <Typography variant='p' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                        {props.product.name}
                    </Typography>
                </CardContent>
                <CardContent sx={{ p: 0.5, pl: 1 }}>
                    <Typography variant='p' color='text.secondary'>
                        {currencySymbol(props.currency)} {convertPrice(props.product, props.currency)}
                    </Typography>
                </CardContent>
                <CardActions>
                    {props.product.inventory !== 0 ?
                        <Button variant='contained' onClick={addToCart}
                            sx={{
                                color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
                            }}>ADD TO BAG</Button>
                        : <Button variant='contained' disabled
                            sx={{
                                color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
                            }}>OUT OF STOCK</Button>}
                    {error && <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                {message}
                            </Alert>
                        </Snackbar> }
                    {success && <Snackbar open={open} autoHideDuration={3000} 
                        onClose={handleClose}>
                            <SnackbarContent message={message} sx={{ backgroundColor: "#5cb85c", textTransform: "capitalize"}} />
                        </Snackbar> 
                    }
                </CardActions>
            </Card>
        </>
    )

}

export default ProductCard;