import React from 'react'
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import Paper from '@mui/material/Paper';

const Footer = () => {
    const linkStyle = {
        margin: "5px",
        color: "white",
        fontSize: "14px"
    };

    return (
        <Box sx={{ backgroundColor: "black", mt: 3 }}>
            <Grid container spacing={2}>
                <Grid item {...{ xs: 12, sm: 6, md: 6 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", p: 1, pl: 3, color: "white" }}>
                        <Link style={linkStyle} to={"/return"}>Return & Excahnge</Link>
                        <Link style={linkStyle} to={"/shipping"}>Shipping</Link>
                        <Link style={linkStyle} to={"/privacy"}>Privacy Policy</Link>
                        <Link style={linkStyle} to={"/terms"}>Terms</Link>
                    </Box>
                </Grid>
                <Grid item {...{ xs: 12, sm: 6, md: 6 }}>
                    <Box sx={{ display: "flex", flexDirection: "column", padding: "10px 0 10px 24px" }}>
                        <Paper elevation={0} sx={{backgroundColor:"black", mb:2}}>
                            <Typography color="white" variant="p" fontSize={14}>Follow Us:</Typography> <br />
                            <Link to='https://www.instagram.com//blaxmit.jewelry' target="_blank">
                                <InstagramIcon sx={{ color: "white", ml: 1, mt: 0.5 }} />
                            </Link>
                        </Paper>
                        <Paper elevation={0} sx={{backgroundColor:"black"}}>
                            <Typography color="white" variant="p" fontSize={14}>Contact Us:</Typography> <br/>
                            <Typography color="white" variant="p" fontSize={14} ml={1}>sales@blaxmit.com</Typography>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}

export default Footer