import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getPaymentSession } from '../services/CheckoutService';
import { AddPaymentToCart } from '../services/ShoppingCart';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const style = {
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
};

const Success = (props) => {
    const location = useLocation();
    let navigate = useNavigate(); 
    const queryParams = new URLSearchParams(location.search)
    const [id, setId] = useState(queryParams.get('session_id'))
    const [session, setSession] = useState();

    useEffect(() => {
        let ignore = false;
        getPaymentSession(id).then(session => {
            setSession(session)
            if (!ignore && props.cart.length !== 0) {
                AddPaymentToCart({
                    cartItems: props.cart,
                    email: session.customerEmail,
                    status: session.paymentStatus,
                    paymentDate: session.date,
                    paymentId: session.paymentIntentId
                }).then((data) => {
                    if (data) {
                        localStorage.removeItem('carts');
                        props.setCart([]);
                    }
                })
            }
        })
        return () => { ignore = true }
    }, [])

    const goToHomePage = () => {
        navigate('/')
    }

    return (
        <>
            {session !== undefined &&
                <Card sx={{ p: 1, mt: 3 }}>
                    <CardContent sx={{ p: 0.5, pl: 1 }}>
                        <Typography variant='h5' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                            Thank you for shopping!
                        </Typography>
                    </CardContent>
                    <CardContent sx={{ p: 1 }}>
                        <Typography variant='p' color='text.secondary' sx={{lineHeight: 1.5}}>
                            Your payment number is {session.paymentIntentId}<br />
                        </Typography>
                    </CardContent>
                    <CardContent sx={{ p: 1 }}>
                        <Typography variant='p' color='text.secondary' sx={{lineHeight: 1.5}}>
                            The order confirmation and tracking number will be sent shortly to {session.customerEmail}.
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant='contained' onClick={goToHomePage}
                            sx={{
                                color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.54)',
                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
                            }}>Continue Shopping</Button>
                    </CardActions>
                </Card>}
        </>
    )
}

export default Success


