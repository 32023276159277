import React, { useState, useEffect } from 'react'
import {currencySymbol } from '../utilities/ConvertPrice'
import EmailModal from './Modals/EmailModal'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


const TotalPriceChange = (props) => {
    const [modalOpen, setModalOpen] = useState(false);


    const handleClick = () => {
        setModalOpen(true)
        props.setDrawerOpen(false)
    }

    return (
        <Box sx={{ m: 2, backgroundColor: 'primary' }} >
            <Stack direction='row' justifyContent="space-between" alignItems="center" sx={{ m: 1 }}>
                <Paper elevation={0}>
                    Subtotal
                </Paper>
                <Paper elevation={0}>
                    {currencySymbol(props.cart[0].currency)} {props.totalPrice}
                </Paper>
            </Stack>
            <Stack direction='row' justifyContent="space-between" alignItems="center" sx={{ m: 1 }}>
                <Paper elevation={0}>
                    Shipping
                </Paper>
                <Paper elevation={0}>
                    Calculated in checkout
                </Paper>
            </Stack>
            <Button variant='contained' size="large" onClick={handleClick}
                sx={{
                    mt: 2, mb: 2,
                    width: '100%',
                    color: 'white', mb: 2, backgroundColor: '#000000',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
                }}>Checkout</Button>
             <EmailModal setOpen = {setModalOpen} open = {modalOpen} 
                cart ={props.cart} totalPrice ={props.totalPrice} />
        </Box>
    )
}
export default TotalPriceChange