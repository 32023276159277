import React from 'react'
import QuantityChange from './QuantityChange';
import TotalPriceChange from './TotalPriceChange';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Divider from '@mui/material/Divider';


const ShoppingCart = (props) => {
    const handleDelete = (id) => {
        let cartArr = props.cart.filter(item => item.productId !== id);
        props.setCart(cartArr);
        localStorage.clear();
        localStorage.setItem('carts', JSON.stringify(cartArr));
        const total = cartArr.reduce((a,v) =>  a = a + v.cartItemPrice , 0 ).toFixed(2)
        props.setTotalPrice(Number(total))
    }

    return (
        <>
            <Stack direction='column' justifyContent="space-between" sx={{ height: 1 }} >
                <div>
                    {props.cart.length !== 0 ? props.cart.map((item, index) => (
                        <div key={index}>
                            <Stack direction='row' justifyContent="space-between" alignItems="center" sx={{ m: 1 }}>
                                <Paper elevation={0} sx={{textTransform: 'capitalize'}}>
                                    {item.name}
                                </Paper>
                                <Paper elevation={0}>
                                    <IconButton>
                                        <DeleteOutlinedIcon onClick={() => handleDelete(item.productId)} />
                                    </IconButton>
                                </Paper>
                            </Stack>
                            
                            <QuantityChange cartItem={item} cart={props.cart} setCart={props.setCart} 
                                    setTotalPrice = {props.setTotalPrice}  totalPrice = {props.totalPrice} />

                            <Divider />
                        </div>
                    )) :
                        <Box sx={{ m: 2 }}>
                            <Typography variant='h6'>
                                Your bag is empty. <br />
                                Fill your Shopping Bag with the pieces you love!
                            </Typography>
                        </Box>}
                </div>
                 {props.cart.length !== 0 &&
                    <TotalPriceChange totalPrice = {props.totalPrice} cart ={props.cart} 
                        setDrawerOpen = {props.setDrawerOpen} />
                } 
            </Stack>
        </>
    )
}

export default ShoppingCart

