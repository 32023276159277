export const getProducts = async (filterObj) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/all`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Category : filterObj.category, 
                PriceOrder : filterObj.priceOrder
            }),
        });

        let data = await response.json();
        return data;

    } catch(e) {
        console.log(e);
    }
}

export const getProductById = async (id) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`, {
            method: "GET",
        });

        let data = await response.json();
        return data;

    } catch(e) {
        console.log(e);
    }
}

export const getMostLikedProducts = async (filterObj) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/product/mostliked`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Category : filterObj.category, 
                ProductId : filterObj.productId
            }),
        });

        let data = await response.json();
        return data;

    } catch(e) {
        console.log(e);
    }
}



    

