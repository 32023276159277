import React, { useState, useEffect, forwardRef } from 'react'
import { addReview, getProductReviews } from '../services/ProductReviewService'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Reviews = forwardRef((props, ref) => {
    const [productReview, setProductReview] =
        useState({ productId: props.productId, userEmail: '', rate: 5, review: '' })
    const [reviews, setReviews] = useState()

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#000000',
        },
        '& .MuiRating-iconHover': {
            color: '#000000',
        },
    });

    useEffect(() => {
        if (props.productId !== undefined)
            getProductReviews(props.productId).then(pr => setReviews(pr))
    }, [])

    const handleChange = (e) => {
        setProductReview({ ...productReview, [e.target.name]: e.target.value })
    }

    const submitReview = () => {
        addReview(productReview).then(data => {
            if (data) {
                setReviews([...reviews, productReview])
                setProductReview({ productId: props.productId, userEmail: '', rate: 5, review: '' })
            }
        })
    }

    return (
        <div id='reviews' ref={ref}>
            {reviews && <Box mt={3} p={3} sx={{ backgroundColor: "#f2f1f1" }}>
                <Card >
                    <CardContent>
                        <Typography variant="h5" color="text.secondary" gutterBottom>
                            Write Review
                        </Typography>
                        <StyledRating
                            name="customized-color"
                            value={productReview.rate}
                            onChange={(e) => {
                                setProductReview({ ...productReview, rate: e.target.value })
                            }}
                        />
                        <TextField id="outlined-basic" label="Your name/email" sx={{ marginBottom: "10px", marginTop: "10px" }}
                            value={productReview.userEmail}
                            variant="outlined"
                            fullWidth
                            name='userEmail'
                            onChange={handleChange}
                        />
                        <TextField
                            value={productReview.review}
                            id="outlined-multiline-static"
                            label="Your review"
                            multiline
                            rows={3}
                            fullWidth
                            name='review'
                            onChange={handleChange}
                        />
                    </CardContent>
                    <CardActions>
                        <Button variant='contained' size="large" onClick={submitReview}
                            sx={{
                                color: 'white', mb: 2, backgroundColor: 'rgba(0, 0, 0, 0.54)', marginLeft: '10px',
                                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
                            }}>Submit Your Review</Button>
                    </CardActions>
                </Card>
                {reviews.length > 0 && <Box mt={3}>
                    <Typography variant="h5" color="text.secondary" gutterBottom>
                        Reviews
                    </Typography>
                    {reviews.map((review, index) => (
                        <Box ke={index}>
                            <Divider m={1} />
                            <Stack direction='row' justifyContent="flex-start" alignItems="center" p={1} >
                                <Avatar>{review.userEmail.charAt(0).toUpperCase()}</Avatar>
                                <div>
                                    <Typography variant="div" sx={{ fontSize: "16px", padding: "10px" }}>{review.userEmail}</Typography>
                                    <br />
                                    <StyledRating name="read-only" value={review.rate} readOnly
                                        size="small"
                                        sx={{ paddingLeft: "10px" }}
                                    />
                                </div>
                            </Stack>
                            <Typography variant="p" component="div" p={1}>{review.review}</Typography>
                        </Box>
                    ))}
                </Box>}
            </Box>}
        </div>
    )
})

export default Reviews