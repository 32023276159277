import React from 'react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const ProductImageList = (props) => {

    return (
        <ImageList variant="masonry" cols={2} gap={8}>
            {props.paths.map((path, index) => (
                <ImageListItem key={index}>
                    <img
                        src={`${path}?w=350&fit=crop&auto=format`}

                        loading="lazy"
                    />
                </ImageListItem>))}
        </ImageList>
    )
}

export default ProductImageList