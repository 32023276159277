export const createPaymentSession = async (cart, email, totalPrice) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/checkout`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                CartItems : cart,
                CustomerEmail : email, 
                CartAmount : totalPrice
            }),
        });
        let data = response.json();
        return data;
    } catch(e) {
        console.log(e);
    }
}

export const getPaymentSession = async (id) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/checkout/${id}`, {
            method: "GET",
        });

        let data = await response.json();
        return data;

    } catch(e) {
        console.log(e);
    }
}
