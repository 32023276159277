import React, { useState, useEffect, createRef } from 'react';
import { getProducts } from '../services/ProductService'
import Banner from './Banner'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ProductCard from './ProductCard';
import FilterDrawer from './FilterDrawer';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircularProgress from '@mui/material/CircularProgress';

const Products = (props) => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState();
    const [filter, setFilter] = useState({ priceOrder: '', category: '' });
    const rootElement = document.getElementById("back-to-top-anchor");
    const ref = createRef(rootElement);


    useEffect(() => {
        getProducts(filter).then((products) => {
            setProducts(products)
            setLoading(false);
        });
    }, [filter])

    const handleClick = () => {
        ref.current.scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <>
            <Banner ref={ref} />
            <FilterDrawer filter={filter} setFilter={setFilter} />
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} >
                    {loading ? <CircularProgress color="inherit" sx={{ width:"100%", margin:"auto" }} p={5} />
                    : products.map((product, index) => (
                        <Grid item {...{ xs: 12, sm: 6, md: 4 }} key={index} justifyContent="center">
                            <ProductCard product={product} cart={props.cart} setCart={props.setCart}
                                totalPrice={props.totalPrice} setTotalPrice={props.setTotalPrice} currency={props.currency} />
                        </Grid>
                    ))
                    }
                </Grid>
            </Box>
            <Box>
                <Fab size="small" aria-label="scroll back to top" 
                    sx={{ position: 'fixed', bottom: 25, right: 16 }} onClick={handleClick}>
                    <KeyboardArrowUpIcon />
                </Fab>
            </Box>

        </>
    )
}



export default Products
