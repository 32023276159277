import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import ShoppingCart from './ShoppingCart'
import CurrencyChange from "./CurrencyChange";
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Badge from '@mui/material/Badge';
import Logo from '../assets/images/blaxmit_logo1.jpg'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


const Navigation = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <Box sx={{ flexGrow: 1, background: 'black' }}>
      <AppBar position="static">
        <Toolbar sx={{ background: 'black' }}>
          <Box variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to='/'>
              <img src={Logo} height="64px" />
            </Link>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
              onClick={handleDrawerOpen}
            >
              <Badge badgeContent={props.cart.length} color="primary">
                <ShoppingBasketOutlinedIcon />
              </Badge>
            </IconButton>
            <CurrencyChange currency={props.currency} setCurrency={props.setCurrency} />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: 350,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 350,
          },
        }}
        variant="persistent"
        anchor="right"
        open={drawerOpen}
      >
        <DrawerHeader sx={{
          display: 'flex',
          justifyContent: 'space-between', mr: 1, ml: 1
        }}>
          <Typography variant='h6' color='text.secondary'>
            Your Bag
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ShoppingCart setDrawerOpen={setDrawerOpen} cart={props.cart} setCart={props.setCart}
          totalPrice={props.totalPrice} setTotalPrice={props.setTotalPrice} currency={props.currency} />
      </Drawer>
    </Box>
  );

}

export default Navigation