import React from 'react'
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Cancel = () => {
    let navigate = useNavigate(); 

    const goToHomePage = () => {
        navigate('/')
    }

    return (
        <Card sx={{ p: 1, mt: 3 }}>
            <CardContent sx={{ p: 0.5, pl: 1 }}>
                <Typography variant='p' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                    Payment was not successful. Please try again!
                </Typography>
            </CardContent>
            <CardActions>
                <Button variant='contained' onClick={goToHomePage}
                    sx={{
                        color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.54)',
                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.54)' }
                    }}>Home Page</Button>
            </CardActions>
        </Card>
    )
}

export default Cancel