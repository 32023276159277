import React, { useState, useEffect } from 'react'
import {  BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, useTheme, ThemeProvider } from '@mui/material/styles';
import Navigation from './components/Navigation';
import Footer from './components/Footer'
import Products from './components/Products';
import Product from './components/Product'
import Success from './pages/Success';
import Cancel from './pages/Cancel'
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Return from './pages/Return';
import Shipping from './pages/Shipping';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import './App.css'

function App() {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('carts')) || [])
  const [token, setToken] = useState(sessionStorage.getItem("user_key"));
  const [currency, setCurrency] = useState("USD");
  const total = cart.reduce((a, v) => a = a + v.cartItemPrice, 0)
  const totalTwoDigits = total.toFixed(2);
  const [totalPrice, setTotalPrice] = useState(Number(totalTwoDigits));

  const theme = createTheme({
    typography: {
      fontFamily: [
        'Rokkitt', 'serif',
        'sans-serif'
      ].join(','),
    },
  })

  useEffect(() => {
    setCart(JSON.parse(localStorage.getItem('carts')) || [])
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Navigation cart={cart} setCart={setCart} totalPrice={totalPrice}
            setTotalPrice={setTotalPrice} token={token} setToken={setToken}
            currency={currency} setCurrency={setCurrency}  />
          <Container sx={{flexGrow: 1, minHeight: "100vh" }}>
            <Routes>
                <Route path="/" exact element={<Products cart={cart} setCart={setCart}
                  setTotalPrice={setTotalPrice} totalPrice={totalPrice} currency={currency} />} />
                <Route path="/:id" exact element={<Product cart={cart} setCart={setCart}
                  setTotalPrice={setTotalPrice} totalPrice={totalPrice} currency={currency} />} />
                <Route path="/return" exact element={<Return />} />
                <Route path="/shipping" exact element={<Shipping />} />
                <Route path="/terms" exact element={<Terms />} />
                <Route path="/privacy" exact element={<Privacy />} />
              <Route path="/success" exact element={<Success cart={cart} setCart={setCart}
                token={token} />} />
              <Route path="/cancel" exact element={<Cancel />} />
            </Routes>
          </Container>
          <Footer />
        </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
