import React, { useState } from 'react'
import {currencySymbol } from '../utilities/ConvertPrice'
import { styled, createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';

const QuantityChange = (props) => {
    const [quantity, setQuantity] = useState(props.cartItem.quantity);
    const [itemPrice, setItemPrice] = useState(props.cartItem.cartItemPrice);
    const [isAvailable, seAvailable] = useState(true);

    const theme = createTheme({
        palette: {
            primary: {
                main: grey[700],
            },
            secondary: {
                main: grey[700],
            },
        },
    });

    const increaseQuantity = (id) => {
        props.cart.map((c) => {
            if (c.id === id && quantity + 1 <= c.inventory) {
                const quantityInctreased = quantity + 1;
                setQuantity(quantityInctreased);
                const priceIncreased = (itemPrice + props.cartItem.price).toFixed(2);
                setItemPrice(Number(priceIncreased));
                c.quantity++;
                c.cartItemPrice = Number(priceIncreased);
            }
            else if (quantity + 1 > c.inventory) {
                seAvailable(false)
            }
        })
        const total = props.cart.reduce((a,v) =>  a = a + v.cartItemPrice , 0 ).toFixed(2)
        props.setTotalPrice(Number(total))
        localStorage.clear();
        localStorage.setItem('carts', JSON.stringify(props.cart));
    }

    const decreaseQuantity = (id) => {
        props.cart.map((c) => {
            if (c.id === id && c.quantity - 1 !== 0) {
                const quantityDecreased = quantity - 1;
                setQuantity(quantityDecreased);
                const priceDecreased = (itemPrice - props.cartItem.price).toFixed(2);
                setItemPrice(Number(priceDecreased));
                c.quantity--;
                c.cartItemPrice = Number(priceDecreased);
            } else if (c.quantity - 1 === 0) {
                let cartArr = props.cart.filter(item => item.id !== id);
                props.setCart(cartArr);
                localStorage.clear();
                localStorage.setItem('carts', JSON.stringify(cartArr));
            }
        })
        const total = props.cart.reduce((a,v) =>  a = a + v.cartItemPrice , 0 ).toFixed(2)
        props.setTotalPrice(Number(total))
        localStorage.clear();
        localStorage.setItem('carts', JSON.stringify(props.cart));
    }

    return (
        <>
            <Stack direction='row' justifyContent="space-between" alignItems="center" sx={{ width: 320, m: 1 }}>
                <Paper elevation={0}  >
                    <ButtonGroup aria-label="button group" sx={{
                        maxWidth: '15px', maxHeight: '20px', minWidth: '15px', minHeight: '20px'
                    }}>
                        <Button theme={theme} color="primary" onClick={() => decreaseQuantity(props.cartItem.id)} sx={{
                            p: 0, "&.MuiButtonGroup-grouped": {
                                maxWidth: '25px',
                                minWidth: '25px'
                            }
                        }}><strong>-</strong></Button>
                        <Button disabled sx={{
                            p: 0,
                            "&.Mui-disabled": {
                                borderColor: 'rgba(97, 97, 97, 0.5)',
                                color: '#616161'
                            },
                            "&.MuiButtonGroup-grouped": {
                                maxWidth: '25px',
                                minWidth: '25px'
                            }
                        }}>{quantity}</Button>
                        <Button theme={theme} color="primary" onClick={() => increaseQuantity(props.cartItem.id)} sx={{
                            p: 0, "&.MuiButtonGroup-grouped": {
                                maxWidth: '25px',
                                minWidth: '25px'
                            }
                        }}><strong>+</strong></Button>
                    </ButtonGroup>
                </Paper>
                <Paper elevation={0}>
                    {currencySymbol(props.cart[0].currency)} {itemPrice}
                </Paper>
            </Stack>
            {!isAvailable && <Alert severity="error">So sorry! Our inventory is low</Alert>}


        </>
    )
}
export default QuantityChange;
