import React, { useState } from 'react'
import { styled, useTheme, createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Breadcrumbs from '@mui/material/Breadcrumbs';


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const FilterDrawer = (props) => {
    const theme = createTheme({
        palette: {
          primary: {
            main: grey[700],
          },
          secondary: {
            main: '#f44336',
          },
        },
      });
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const handleChange = (event) => {
        props.setFilter({ ...props.filter, 'priceOrder': event.target.value });
    };

    const handleCategoryChange = (event) => {
        props.setFilter({ ...props.filter, 'category': event.target.value })
    }

    const list = () => (
        <Box
            sx={{ width: 350, display: 'flex', flexDirection: 'column' }}
            role="presentation"
        >
            <FormControl sx={{ width: 320, m: 1, alignSelf: 'center' }}>
                <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.filter.priceOrder}
                    label="SortBy"
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value='lowest'>Price : Low to High</MenuItem>
                    <MenuItem value='highest'>Price : High to Low</MenuItem>
                </Select>
            </FormControl>
            <FormControl sx={{ m: 2}}>
                <FormLabel id="demo-radio-buttons-group-label">Category</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    defaultValue=""
                    onChange={handleCategoryChange}
                    sx={{ m: 2}}
                >
                    <FormControlLabel value="" control={<Radio  size="small" color="default" />} label="All" />
                    <FormControlLabel value="ring" control={<Radio  size="small" color="default" />} label="Ring" />
                    <FormControlLabel value="earrings" control={<Radio  size="small" color="default" />} label="Earrings" />
                    <FormControlLabel value="bracelet" control={<Radio  size="small" color="default" />} label="Bracelet" />
                    <FormControlLabel value="necklace" control={<Radio  size="small" color="default" />} label="Necklace" />
                    <FormControlLabel value="pendant" control={<Radio  size="small" color="default" />} label="Pendant" />
                    <FormControlLabel value="chain" control={<Radio  size="small" color="default" />} label="Chain" />
                </RadioGroup>
            </FormControl>
        </Box>
    );
    return (
        <>
            <Button  theme = {theme} variant="outlined" color="primary" size="large" 
                sx = {{m :2 }} onClick={handleDrawerOpen}><strong>Filter + Sort</strong></Button>
            {props.filter.category !== '' && 
                 <Breadcrumbs aria-label="breadcrumb" sx={{m:2}}>
                 <Typography color="text.primary"> All Products</Typography>
                 <Typography color="text.primary">{props.filter.category}</Typography>
             </Breadcrumbs>
            }
            <Drawer
                sx={{
                    width: 350,
                    flexShrink: 0
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{
                    display: 'flex',
                    justifyContent: 'space-between', mr: 1, ml: 1
                }}>
                    <Typography variant='h6' color='text.secondary'>
                        Filters
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                <Divider />

                {list()}
            </Drawer>
        </>
    )

}

export default FilterDrawer