import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const Privacy = () => {
    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ m: 2 }}>
                <Link
                    underline="hover"
                    color="inherit"
                    href="/"
                >
                    Home
                </Link>
                <Typography color="text.primary">Privacy Policy</Typography>
            </Breadcrumbs>
            <Card sx={{ p: 1, mt: 3, mb: 2 }}>
                <CardContent sx={{ p: 0.5, pl: 1 }}>
                    <Typography variant='h6' color='text.secondary' sx={{ textTransform: 'capitalize' }}>
                        PRIVACY POLICY
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        This Privacy Policy describes how blaxmit.com (the “Site”
                        or “we”) collects, uses, and discloses your Personal
                        Information when you visit or make a purchase from the
                        Site.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Collecting Personal Information
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        When you visit the Site, we collect certain information
                        about your device, your interaction with the Site, and
                        information necessary to process your purchases. We may
                        also collect additional information if you contact us for
                        customer support. In this Privacy Policy, we refer to any
                        information that can uniquely identify an individual
                        (including the information below) as “Personal
                        Information”. See the list below for more information about
                        what Personal Information we collect and why.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Device information
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        Examples of Personal Information
                        collected: version of web browser, IP address, time
                        zone, cookie information, what sites or products you
                        view, search terms, and how you interact with the
                        Site. <br />
                        Purpose of collection: to load the Site accurately for
                        you, and to perform analytics on Site usage to
                        optimize our Site. <br />
                        Source of collection: Collected automatically when
                        you access our Site using cookies, log files, web
                        beacons, tags, or pixels.<br />
                        Disclosure for a business purpose: shared with our
                        processor Stripe.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Order information
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        Examples of Personal Information
                        collected: name, billing address, shipping address,
                        payment information (including credit card numbers,
                        Debit Visa), email address, and phone number.<br />
                        Purpose of collection: to provide products or
                        services to you to fulfill our contract, to process your
                        payment information, arrange for shipping, and
                        provide you with invoices and/or order confirmations,
                        communicate with you, screen our orders for potential
                        risk or fraud, and when in line with the preferences
                        you have shared with us, provide you with information
                        or advertising relating to our products or services.<br />
                        Source of collection: collected from you. <br />
                        Disclosure for a business purpose: shared with our
                        processor Stripe and Shippo.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Customer support information
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        Examples of Personal Information
                        collected: Name, Phone number, email <br />
                        Purpose of collection: to provide customer support.<br />
                        Source of collection: collected from you.<br />
                        Disclosure for a business purpose: shared with our
                        processor Stripe and Shippo.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Sharing Personal Information
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        We share your Personal Information with service providers
                        to help us provide our services and fulfill our contracts with
                        you, as described above. For example: <br />
                        We may share your Personal Information to comply
                        with applicable laws and regulations, to respond to a
                        subpoena, search warrant or other lawful request for
                        information we receive, or to otherwise protect our
                        rights.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Behavioral Advertising
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        As described above, we use your Personal Information to
                        provide you with targeted advertisements or marketing
                        communications we believe may be of interest to you. For
                        example:<br />
                        We use Google Analytics to help us understand how
                        our customers use the Site. You can read more about
                        how Google uses your Personal Information
                        here: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>.You
                        can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. <br />
                        We share information about your use of the Site, your
                        purchases, and your interaction with our ads on other
                        websites with our advertising partners. We collect and
                        share some of this information directly with our
                        advertising partners, and in some cases through the
                        use of cookies or other similar technologies (which
                        you may consent to, depending on your location).
                        For more information about how targeted advertising
                        works, you can visit the Network Advertising Initiative’s
                        (“NAI”) educational page at http://
                        www.networkadvertising.org/understanding-onlineadvertising/how-does-it-work.
                        You can opt out of targeted advertising by: <br />
                        <a href="https://www.facebook.com/settings/?tab=ads">FACEBOOK</a>   <br />
                        <a href="https://www.google.com/settings/ads/anonymous">GOOGLE</a>   <br />
                        <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">BING</a> <br />
                        Additionally, you can opt out of some of these services by
                        visiting the Digital Advertising Alliance’s opt-out portal
                        at: <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Using Personal Information
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        We use your personal Information to provide our services
                        to you, which includes: offering products for sale,
                        processing payments, shipping and fulfillment of your
                        order, and keeping you up to date on new products,
                        services, and offers.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Lawful basis
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        Pursuant to the General Data Protection Regulation
                        (“GDPR”), if you are a resident of the European Economic
                        Area (“EEA”), we process your personal information under
                        the following lawful bases:
                        <ul>
                            <li>Your consent</li>
                            <li>The performance of the contract between you and the Site</li>
                            <li>Compliance with our legal obligations</li>
                            <li>To protect your vital interests</li>
                            <li>To perform a task carried out in the public interest</li>
                            <li>For our legitimate interests, which do not override
                                your fundamental rights and freedoms.</li>
                        </ul>
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Retention
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        When you place an order through the Site, we will retain
                        your Personal Information for our records unless and until
                        you ask us to erase this information. For more information
                        on your right of erasure, please see the ‘Your rights’
                        section below.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Automatic decision-making
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        If you are a resident of the EEA, you have the right to
                        object to processing based solely on automated decisionmaking (which includes profiling), when that decisionmaking has a legal effect on you or otherwise significantly
                        affects you.
                        We do not engage in fully automated decision-making that
                        has a legal or otherwise significant effect using customer
                        data.
                        Services that include elements of automated decisionmaking include:
                        <ul>
                            <li>Temporary denylist of IP addresses associated with
                                repeated failed transactions. This denylist persists for
                                a small number of hours.</li>
                            <li>Temporary denylist of credit cards associated with
                                denylisted IP addresses. This denylist persists for a
                                small number of days.</li>
                        </ul>
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        GDPR
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        If you are a resident of the EEA, you have the right to
                        access the Personal Information we hold about you, to
                        port it to a new service, and to ask that your Personal
                        Information be corrected, updated, or erased. If you would
                        like to exercise these rights, please contact us through the
                        contact information below.
                        Your Personal Information will be initially processed in
                        Ireland and then will be transferred outside of Europe for
                        storage and further processing, including to Canada and
                        the United States.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        CCPA
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        If you are a resident of California, you have the right to
                        access the Personal Information we hold about you (also
                        known as the ‘Right to Know’), to port it to a new service,
                        and to ask that your Personal Information be corrected,
                        updated, or erased. If you would like to exercise these
                        rights, please contact us through the contact information
                        below.
                        If you would like to designate an authorized agent to
                        submit these requests on your behalf, please contact us at
                        the address below.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Cookies
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        A cookie is a small amount of information that’s
                        downloaded to your computer or device when you visit our
                        Site. We use a number of different cookies, including
                        functional, performance, advertising, and social media or
                        content cookies. Cookies make your browsing experience
                        better by allowing the website to remember your actions
                        and preferences (such as login and region selection). This
                        means you don’t have to re-enter this information each
                        time you return to the site or browse from one page to
                        another. Cookies also provide information on how people
                        use the website, for instance whether it’s their first time
                        visiting or if they are a frequent visitor.
                        We use the following cookies to optimize your experience
                        on our Site and to provide our services. <br />
                        The length of time that a cookie remains on your computer
                        or mobile device depends on whether it is a “persistent” or
                        “session” cookie. Session cookies last until you stop
                        browsing and persistent cookies last until they expire or
                        are deleted. Most of the cookies we use are persistent and
                        will expire between 30 minutes and two years from the
                        date they are downloaded to your device.
                        You can control and manage cookies in various ways.
                        Please keep in mind that removing or blocking cookies
                        can negatively impact your user experience and parts of
                        our website may no longer be fully accessible.
                        Most browsers automatically accept cookies, but you can
                        choose whether or not to accept cookies through your
                        browser controls, often found in your browser’s “Tools” or
                        “Preferences” menu. For more information on how to
                        modify your browser settings or how to block, manage or
                        filter cookies can be found in your browser’s help file or
                        through such sites as <a href="www.allaboutcookies.org">allaboutcookies.org</a>.
                        Additionally, please note that blocking cookies may not
                        completely prevent how we share information with third
                        parties such as our advertising partners. To exercise your
                        rights or opt-out of certain uses of your information by
                        these parties, please follow the instructions in the
                        “Behavioural Advertising” section above.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Do Not Track
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        Please note that because there is no consistent industry
                        understanding of how to respond to “Do Not Track”
                        signals, we do not alter our data collection and usage
                        practices when we detect such a signal from your browser.

                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Changes
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        We may update this Privacy Policy from time to time in
                        order to reflect, for example, changes to our practices or
                        for other operational, legal, or regulatory reasons.
                    </Typography>
                    <Typography variant='h6' color='text.secondary' fontSize={18} sx={{ textTransform: 'capitalize' }}>
                        Contact
                    </Typography>
                    <Typography variant='p' color='text.secondary' fontSize={14}>
                        For more information about our privacy practices, if you
                        have questions, or if you would like to make a complaint,
                        please contact us by e-mail at sales@blaxmit.com
                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export default Privacy
