import React, {forwardRef} from 'react'
import banner from '../assets/images/banner.jpeg'

const Banner = forwardRef((props, ref) => {
  
    return (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "10px" }} >
            <img style={{ width:"100%"}}
            src={banner}
            alt="banner1"
            loading="lazy"
            ref={ref} id="back-to-top-anchor" />
        </div>
    )
})

export default Banner