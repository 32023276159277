export const getUserShoppingCart = async (userId) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shoppingcart/${userId}`, {
            method: "GET",
        });

        let data = await response.json();
        return data;

    } catch(e) {
        console.log(e);
    }
}

export const AddPaymentToCart = async (cartDto) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/shoppingcart`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Email : cartDto.email,
                CartItems : cartDto.cartItems,
                Status : cartDto.status, 
                PaymentDate : cartDto.paymentDate, 
                PaymentId : cartDto.paymentId
            }),
        });
        if (response.ok) {
            return true;
        }

    } catch(e) {
        console.log(e);
    }
}