export const getProductReviews = async (productId) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/productReview/reviews/${productId}`, {
            method: "Get",
        });

        let data = await response.json();
        return data;

    } catch(e) {
        console.log(e);
    }
}

export const addReview = async (productReview) => {
    try {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/ProductReview`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ProductId : productReview.productId, 
                Rate : productReview.rate, 
                UserEmail : productReview.userEmail,
                Review : productReview.review
            }),
        });
        console.log(response)
        if (response.ok) {
            return true;
        }

    } catch(e) {
        console.log(e);
    }
}